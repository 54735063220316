<template>
  <div class="home">
    <payment-form></payment-form>
  </div>
</template>

<script>
// @ is an alias to /src
import PaymentForm from '@/components/PaymentForm'

export default {
  name: 'Home',
  components: {
    PaymentForm
  }
}
</script>
