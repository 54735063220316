





























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import PayPal from 'vue-paypal-checkout';

@Component({
  components: {
    PayPal
  }
})
export default class PaymentForm extends Vue {
  @Prop() private goal!: any;
  // amount: number;
  private ownAmount: boolean;
  private amount: number | null;
  private firstname: string;
  private lastname: string;
  private email: string;
  private loading: boolean;
  private errorinfo: boolean;
  private errorMessage: string;
  // private readonly host = 'http://127.0.0.1:8000/payment';
  private host; // = 'https://api.prospe.atpsolutions.ovh/payment'
  private hostPaypal;
  private customAmount = [50, 100, 200];
  private step1 : boolean;
  private step2 : boolean;
  public settings: Settings;
  private paypal = {
    sandbox: 'AQC8VjGfOKbfK1hbRVdDSEHdVasCo5hAP42FwZNUcrx1YX33Nh6jHaDU1yB7VaquQIEsYGTJ18DeV8Oz',
    production: 'Abwhrca7Qnf4ezbB3tmJZgQZIr2KHGkOHE6v5yP-RZFYDpbBr5YkFOnlce1o9U9l18MZSdz7erFdi1qM'
  }

  public constructor() {
    super();
    this.amount = 50;
    this.loading = false;
    this.ownAmount = false;
    this.errorinfo = false;
    this.firstname = '';
    this.lastname = '';
    this.email = '';
    this.errorMessage = '';
    this.step1 = true;
    this.step2 = false;
    this.settings = new Settings();
    this.host = this.settings.settings.host + '/payment';
    this.hostPaypal = this.settings.settings.host + '/payment/paypal';

  }

  public preparePaypalItem() {
    return [{
      "name": this.goal !== undefined ? this.goal.name : 'Unset',
      "description": this.goal !== undefined ? this.goal.paymentDescription : 'Unset',
      "quantity": "1",
      "price": this.amount.toString(),
      "currency": "PLN"
    }];
  }

  public completePaymentPaypal(data) {
    console.log(data);
  }

  // data() {
  //   return {
  //     amount: null
  //   }
  // }

  toggleOwnAmount(): void {
    this.ownAmount = !this.ownAmount;
    this.amount = 200;
  }

  setAmount(amount: number): void {
    this.amount = amount;
    this.ownAmount = false;
  }

  goToStep1() {
    this.step1 = true;
    this.step2 = false;
  }
  goToStep2() {
    this.errorinfo = false;
    if (this.amount === null) {
      this.errorinfo = true;
      this.errorMessage = 'Nie podano wpłacanej wartości. Proszę uzupełnić wartość';
      return;
    } else {
      this.step2 = true;
      this.step1 = false;
    }
  }

  setPayment(): void {
    this.errorinfo = false;
    if (this.firstname.length === 0 || this.lastname.length === 0 || this.email.length === 0) {
      this.errorinfo = true;
      this.errorMessage = 'Proszę uzupełnić dane osobowe.';
      return;
    }
    this.loading = true;
    this.$http.get(this.host, {
      headers: {
        // 'Access-Control-Allow-Origin': '*'
      },
      params: {
        amount: this.amount * 100,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        code: this.goal !== undefined ? this.goal.code : undefined
      }
    }).then((response) => {
      //console.log(response.data);
      if (response.data.status === 'SUCCESS') {
        // window.location = response.data.response.redirectUri;
        window.parent.location.href = response.data.response.redirectUri;
      } else {
        alert('Wystąpił błąd podczas płatności');
      }
      this.loading = false;
    }, (error) =>  {
      this.loading = false;
      //console.log(error);
    })
  }

  setPaymentPaypal(): void {
    this.errorinfo = false;
    if (this.firstname.length === 0 || this.lastname.length === 0 || this.email.length === 0) {
      this.errorinfo = true;
      this.errorMessage = 'Proszę uzupełnić dane osobowe.';
      return;
    }
    this.loading = true;
    this.$http.get(this.hostPaypal, {
      headers: {
        // 'Access-Control-Allow-Origin': '*'
      },
      params: {
        amount: this.amount * 100,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        code: this.goal !== undefined ? this.goal.code : undefined
      }
    }).then((response) => {
      //console.log(response.data);
      if (response.data.status === 'SUCCESS') {
        // window.location = response.data.response.redirectUri;
        window.parent.location.href = response.data.response.redirectUri;
      } else {
        alert('Wystąpił błąd podczas płatności');
      }
      this.loading = false;
    }, (error) =>  {
      this.loading = false;
      //console.log(error);
    })
  }
}
